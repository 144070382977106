/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import querystring from "query-string"
import { useIsClient } from "../hooks/use-is-client"
import Axios from "axios"
import Container from "../components/container"
import { renderLineBreak } from "../helpers"
import Accordion from "../components/accordion"

const LogsPage = ({ data, ...restProps }) => {
  const {
    site: {
      siteMetadata: { apiUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          apiUrl
        }
      }
    }
  `)
  const isClient = useIsClient()
  const query = isClient && querystring.parse(window.location.search)
  const secret = query?.secret
  const [response, setResponse] = React.useState(null)
  React.useEffect(() => {
    if (secret && apiUrl && isClient && !response) {
      Axios.get(apiUrl + "/logs?secret=" + secret)
        .then(response => {
          setResponse(response.data)
        })
        .catch(err => {})
    }
  }, [apiUrl, secret, setResponse, isClient])
  return response ? (
    <Container>
      <Accordion
        items={[
          {
            title: <div>Logs</div>,
            content: response.stdout ? (
              <p sx={{ fontFamily: "monospace" }}>
                {renderLineBreak(response.stdout)}
              </p>
            ) : null,
          },
          {
            title: "Errors",
            content: response.stderr ? (
              <p sx={{ fontFamily: "monospace" }}>
                {renderLineBreak(response.stderr)}
              </p>
            ) : null,
          },
        ]}
      />
    </Container>
  ) : null
}

export default LogsPage
